<template>
  <div class="card-container">
    <div class="opportunities-cards">
      <div class="column-cards" v-for="(column, columnIndex) in columnizedCards" :key="columnIndex">
        <div
            class="container-cards"
            v-for="(card, cardIndex) in column"
            :key="cardIndex"
            @click="navigateTo(card.url)"
            v-if="cardIndex < visibleCardsCount"
        >
          <test-new-tooltips :card="card" :selectedLanguage="selectedLanguage" />
        </div>
      </div>
    </div>
    <button v-if="hasMoreCards || isShowingLess" @click="toggleShowMoreLess" class="btn-secondary">{{ showMoreLessText }}</button>
  </div>
</template>

<script>
import TestNewTooltips from "@/components/testNewTooltips.vue";
import { fetchBadges } from "@/main.js"; // Убедитесь, что путь правильный

export default {
  name: 'NewOpportunityCard',
  components: {TestNewTooltips},
  props: ['selectedLanguage'],

  data() {
    return {
      badges: [],
      visibleCardsCount: 6,
      isShowingLess: false
    };
  },

  async mounted() {
    await this.loadBadges();
    this.updateVisibleCards();
    window.addEventListener('resize', this.updateVisibleCards);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateVisibleCards);
  },

  methods: {
    async loadBadges() {
      try {
        const data = await fetchBadges();
        this.badges = data;
      } catch (error) {
        console.error('Error fetching badges:', error);
      }
    },
    navigateTo(url) {
      const fullUrl = `${window.location.origin}/page/${url}`;
      window.open(fullUrl, '_blank');
    },
    toggleShowMoreLess() {
      if (this.isShowingLess) {
        this.visibleCardsCount = 6;
        this.isShowingLess = false;
      } else {
        this.visibleCardsCount = this.badges.length;
        this.isShowingLess = true;
      }
    },
    updateVisibleCards() {
      if (window.innerWidth <= 1224) {
        this.visibleCardsCount = 6;
      } else {
        this.visibleCardsCount = this.badges.length;
      }
    },
  },

  computed: {
    columnizedCards() {
      if (window.innerWidth <= 1224) {
        return [this.badges];
      } else {
        const columns = [];
        for (let i = 0; i < this.badges.length; i += 2) {
          columns.push(this.badges.slice(i, i + 2));
        }
        return columns;
      }
    },
    hasMoreCards() {
      return this.visibleCardsCount < this.badges.length;
    },
    showMoreLessText() {
      return this.isShowingLess ? 'Show Less' : 'Show More';
    }
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 1280px;
  margin: 0 auto 120px auto;
  overflow-x: auto;
  padding: 16px;

  @media (max-width: 1281px) {
  width: 1200px;
  }

}

.opportunities-cards {
  display: flex;
  gap: 32px;
}

.column-cards {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.container-cards {
  display: flex;
  flex-direction: column;
  gap: 32px;
  cursor: pointer;
}

.card-container::-webkit-scrollbar {
  height: 4px;
}

.card-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 32px;
}

.card-container::-webkit-scrollbar-thumb {
  background: #92c5eb;
  border-radius: 32px;
}

.card-container::-webkit-scrollbar-thumb:hover {
  background: #007acc;
}

.btn-secondary {
  width: 60%;
  font-size: calc(var(--main-font-size) + 2px);
  font-weight: 700;
  padding: 16px 90px;
  gap: 10px;
  line-height: 16px;
  border-radius: 8px;
  border: 2px solid #034694;
  color: #034694;
  text-decoration: none;
  align-self: center;
  margin-top: 16px;
}

.btn-secondary:hover {
  border-color: #034694;
  background-color: #034694;
  color: #ffffff;
}

@media (max-width: 1224px) {

  .card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .opportunities-cards {
    display: grid;
    gap: 16px;
  }

  .column-cards {
    gap: 16px;
  }

  .btn-secondary {
    width: 100%;
    font-size: calc(var(--main-font-size) + 2px);
    padding: 16px 40px;
  }
}
</style>
